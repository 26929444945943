$primary-main: rgba(255, 194, 13, 1);
$secondary-main: rgba(0, 83, 157, 1);
$third-main: #69acdf;
$primary-text: rgba(0, 0, 0, 0.87);
$secondary-text: rgba(0, 0, 0, 0.6);
$third-text: rgba(0, 0, 0, 1);
$diseable-text: rgba(0, 0, 0, 0.38);

$background: #f7fbfd;
$background-card-blue: #eef9ff;
$white: #ffffff;

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Raleway", sans-serif;
  background-color: $background;
  color: $primary-text;
  overflow-x: hidden;
  max-width: 100vw !important;
  min-width: 100vw !important;
  padding: 0 !important;
}

// MENU
// A revoir
// .MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
//   top: 90px !important;
//   right: 55px !important;
//   left: auto !important;
// }

// Modal
.MuiModal-root {
  z-index: 999 !important;
}

.MuiGrid-container {
  width: 100% !important;
  margin: 0 !important;
}

.MuiContainer-maxWidthLg {
  max-width: 1600px !important;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12 {
  padding: 8px !important;
  padding-left: -8px !important;
  width: 100% !important;
}

.MuiGrid-item {
  padding-left: 0 !important;
}

.MuiStepIcon-root.Mui-active,
.MuiStepIcon-root.Mui-completed {
  color: $primary-main !important;
}

div.MuiTabs-scroller {
  button {
    border-radius: 0px !important;
    &.Mui-selected {
      border-bottom: 2px solid $primary-main !important;
    }
  }
}

// CHIP
.MuiChip-root {
  font-family: "Raleway" !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  border: 1px solid $white !important;
  height: 38px !important;
  border-radius: 99px !important;
  &.MuiChip-colorSecondary {
    border: 1px solid $primary-main !important;
    background-color: $primary-main !important;
    color: $primary-text !important;
  }
}

// ToggleButton
.MuiToggleButton-root {
  font-family: "Raleway" !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  background-color: $white !important;
  border: 1px solid $primary-main !important;
  &.Mui-selected.MuiToggleButton-sizeSmall.MuiToggleButton-secondary {
    border: 1px solid $primary-main !important;
    background-color: $primary-main !important;
    color: $primary-text !important;
    font-size: 0.8125rem !important;
    height: 38px !important;
  }
}

// AVATAR
.MuiAvatar-root.MuiAvatar-circular {
  height: 200px;
  width: 200px;
  background-color: transparent;
  color: $primary-main;
  border-radius: 50%;
  background-color: rgb($background-card-blue, 80%);
  cursor: pointer;
  &.selected {
    border: 1px solid $primary-main !important;
    background-color: rgb($primary-main, 50%) !important;
    color: $primary-text !important;
  }
}

// CART DRAWER
.MuiBadge-badge {
  border: 1px solid;
  &.MuiBadge-colorPrimary {
    border-color: $primary-main !important;
    background-color: $white !important;
    color: $secondary-main !important;
  }
}

.MuiSvgIcon-colorPrimary {
  color: $primary-main !important;
}

.MuiDrawer-paperAnchorRight {
  width: 100% !important;
  max-width: 500px !important;
  padding: 24px !important;
  background-color: $background !important;
  border-left: 1px solid $primary-main !important;

  .MuiTypography-h3 {
    font-family: "Gloock";
    color: $secondary-main !important;
    font-size: 34px !important;
    font-weight: 400 !important;
    line-height: 41.99px !important;
    letter-spacing: 0.25px !important;
    text-align: left !important;
  }
  .MuiTypography-body2 {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    letter-spacing: 0.15px !important;
    text-align: left;
  }
  .MuiTypography-body1 {
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    text-align: left;
  }

  .MuiMenuItem-root {
    padding: 4px 8px 4px 8px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
    background-color: $white;
  }

  span.MuiTypography-p {
    font-family: "Raleway";
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    letter-spacing: 0.15px !important;
    text-align: left;

    color: black !important;
  }

  a.link-drawer {
    font-family: "Raleway";
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 19.92px !important;
    letter-spacing: 0.46px !important;
    text-decoration: underline !important;
  }
}

// BADGE
img.badge {
  width: 70px;
}

// UPLOAD FILE
label.upload-file {
  width: 200px;
  min-width: 200px;
  height: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.profile-picture {
    height: 200px;
    min-height: 200px;
  }

  border: 1px dashed $primary-main;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// CARD
div.MuiCard-root {
  border-radius: 0;
  padding: 32px;

  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  &.filter {
    background-color: $white;
    width: 100%;
    border-radius: 8px;
    padding: 24px;
    border: 1px solid $primary-main;
  }

  &.card-resume {
    padding: 24px;
  }
}

label.MuiFormLabel-root {
  &.Mui-focused {
    color: $primary-main !important;
  }
}

div.MuiOutlinedInput-root,
div.MuiInput-underline {
  &.Mui-focused {
    &::after {
      border-bottom-color: $primary-main !important;
    }
    label {
      color: $primary-main !important;
    }
    fieldset {
      border-color: $primary-main !important;
    }
  }
}
button:not(.login-facebook-button) {
  color: $primary-text !important;
  gap: 0px !important;
  border-radius: 99px !important;
  border: 1px !important;
  // opacity: 0px !important;
  font-family: "Raleway" !important;

  &.MuiButton-sizeSmall {
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    padding: 3px 9px 3px 9px !important;
  }

  &.MuiButton-sizeLarge {
    font-size: 15px;
    padding: 8px 22px 8px 22px !important;
    font-weight: 500;
    line-height: 26px;
  }
  white-space: nowrap;
  letter-spacing: 0.46px;
  text-align: left;

  &.Mui-disabled {
    opacity: 0.8;
    background-color: rgba(128, 128, 128, 0.3) !important;
  }

  &.MuiButton-contained {
    background-color: $primary-main;

    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14) !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    border: 1px solid $primary-main !important;

    &:hover {
      background-color: $white;
    }
  }

  &.MuiButton-bordered {
    color: $primary-main !important;
  }

  &.MuiButton-outlined {
    border: 1px solid !important;
    &.MuiButton-colorPrimary {
      border-color: $primary-main !important;
      background-color: $white;
      &:hover {
        background-color: $primary-main;
      }
    }

    &.MuiButton-colorSecondary {
      border-color: $secondary-main !important;
      background-color: $white;
      color: $secondary-main !important;
      &:hover {
        background-color: $secondary-main;
        color: $white !important;
      }
    }

    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14) !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
}

hr.MuiDivider-root {
  border: 4px solid $primary-main;
}

div.MuiDivider-root {
  &::after,
  &::before {
    border-top: 2px solid $primary-main !important;
  }
  color: $primary-main;
  font-weight: 700;
}

h1 {
  color: $white !important;
  font-family: "Gloock" !important;
  font-size: 60px !important;
  font-weight: 400 !important;
  line-height: 72px !important;
  letter-spacing: -0.5px !important;
  text-align: left !important;
  margin: 24px 0 !important;
}

h2 {
  color: $white;
  font-family: "Gloock" !important;
  font-size: 32px !important;
  font-weight: 400;
  line-height: 56.02px;
  text-align: left;
  margin: 2rem 0 1rem 0 !important;
  &.secondary {
    color: $secondary-main !important;
  }
}

h4 {
  color: $secondary-main !important;
  font-family: "Gloock" !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 41.99px !important;
  letter-spacing: 0.25px !important;
  text-align: left !important;
}

h6 {
  font-family: "Gloock" !important;
  color: $secondary-main !important;
  &.secondary {
    color: $secondary-text !important;
  }
  &.third {
    color: $third-text !important;
  }
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0.15px !important;
  text-align: left !important;

  span.secondary {
    font-size: 12px !important;
    font-style: italic;
  }
}

p {
  font-family: "Raleway" !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}

div.card-resume {
  p {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 12px !important;
    letter-spacing: 0.15px !important;
    text-align: left !important;
  }
}

p.MuiTypography-body1 {
  color: $primary-text !important;
  font-family: "Raleway" !important;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
  text-align: left;
  &.secondary {
    color: $secondary-main !important;
  }
}

p.MuiTypography-body2 {
  color: $secondary-main !important;
  font-family: "Raleway" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.17px;
  text-align: left;
}
p.MuiTypography-body2#eur-net {
  color: black !important;
  font-weight: 700 !important;
}

header,
.MuiDrawer-paper {
  a {
    color: $white !important;
    text-decoration: none !important;
    font-family: "Raleway" !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 23.48px !important;
    text-align: left !important;
    white-space: nowrap;
    &:hover {
      text-decoration: underline !important;
      text-decoration-color: $primary-main !important;
    }
    &.underline {
      // border-top: 2px solid $primary-main !important;
      text-decoration: underline !important;
      text-decoration-color: $primary-main !important;
    }
  }
}

.MuiDrawer-paper {
  a {
    color: $secondary-main !important;
  }
}

div.footer {
  div.social {
    svg {
      width: 62px !important;
      height: 62px !important;
      border: 4px solid $secondary-main !important;
      border-radius: 50% !important;
      padding: 8px !important;
      background-color: $white !important;
      color: $secondary-main !important;
    }
  }
  hr.MuiDivider-root {
    border: 1px solid $white;
  }

  p.MuiTypography-body1 {
    color: $white !important;
  }
  a {
    color: $white !important;
    text-decoration: none !important;
    font-family: "Raleway" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 17.6px !important;
    text-align: left !important;
    white-space: nowrap;
    &:hover {
      text-decoration: underline !important;
    }
  }

  h6.MuiTypography-h6 {
    color: $white !important;
    font-weight: 800 !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  h5 {
    font-family: "Raleway" !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 17.6px !important;
    text-align: left !important;
  }

  span.MuiTypography-span {
    font-family: "Raleway" !important;
    font-weight: 500 !important;
    line-height: 17.6px !important;
    margin-bottom: 1rem !important;
  }
}

// google aut button style

.gsi-material-button {
  user-select: none; // Ajout de user-select sans préfixe pour les navigateurs modernes
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: #f2f2f2;
  background-image: none;
  border: none;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  max-width: 250px;
  min-width: min-content;

  .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }

  .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  .gsi-material-button-contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: "Roboto", arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .gsi-material-button-state {
    -webkit-transition: opacity 0.218s;
    transition: opacity 0.218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:disabled {
    cursor: default;
    background-color: #ffffff61;

    .gsi-material-button-state {
      background-color: #1f1f1f1f;
    }

    .gsi-material-button-contents {
      opacity: 38%;
    }

    .gsi-material-button-icon {
      opacity: 38%;
    }
  }

  &:not(:disabled) {
    &:active .gsi-material-button-state,
    &:focus .gsi-material-button-state {
      background-color: #001d35;
      opacity: 12%;
    }

    &:hover {
      -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);

      .gsi-material-button-state {
        background-color: #001d35;
        opacity: 8%;
      }
    }
  }
}

// OFFERS

.offers {
  .MuiGrid-root.MuiGrid-item {
    padding: 1rem !important;
  }
  .MuiCard-root {
    padding: 0 !important;

    border: 1px solid $primary-main;
    &.without-border {
      padding: 1rem !important;
      border: none !important;
      height: 200px;
    }
    &.blue-background {
      border: none;
      background: linear-gradient(180deg, #006db8 0%, #053162 71.5%);
    }

    &.glass-background {
      background-color:transparent;
      box-shadow: none;
      border: none;
      padding: 1rem !important;
    }
  }

  .MuiGrid-container {
    justify-content:center;
  }

  .MuiTypography-p.quotation {
    font-family: "Raleway";
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
  h2 {
    color: $secondary-main;
  }
  h5 {
    font-family: "Gloock";
    color: $secondary-main !important;
    font-size: 40px;
  }

  h6 {
    font-size: 24px !important;
  }

  img {
    &.offer {
      width: 200px;
      height: 200px;
    }
    &.avatar {
      width: 100px;
      height: 100px;
    }
  }

  .each-offer {

    button {
      font-size:12px !important;
    }
  }
}
.gps_dot {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 200px;
  margin-top: 50px;
}
